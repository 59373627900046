import { Radio } from 'antd';
import { CSSProperties } from 'react';
import './large-radio-button.scss';

interface Props {
    onSelect: () => void;
    label: string;
    checked: boolean;
}

export const LargeRadioButton: React.FC<Props> = ({ onSelect, label, checked }: Props) => {
    const containerStyle: CSSProperties = checked
        ? { color: '#317383', fontWeight: 'bold', border: '1px solid #317383' }
        : {};

    return (
        <div className='radio-button-container' style={containerStyle} onClick={onSelect}>
            <div>
                <Radio checked={checked} />
            </div>
            <div>{label}</div>
        </div>
    );
};
