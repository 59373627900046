import { Col, Divider, Row } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import React from 'react';
import { LargeRadioButton, Rate } from '.';
import { formatCurrency } from '../lib/utility';
import { boldCenteredStyle, boldStyle, centerdStyle } from '../main-layout/styles/generic-styles';

interface Props {
    rates: (Rate | null)[];
    showVariantHeader: boolean;
    paymentMethod: DefaultOptionType | null | undefined;
    selectedRateId?: number | undefined;
    onChange?: (rate: Rate) => void;
    style?: React.CSSProperties;
}

export const RateVariantPicker: React.FC<Props> = ({
    rates,
    showVariantHeader,
    paymentMethod,
    onChange,
    selectedRateId,
    style,
}: Props) => {
    const numberToRoman = (value: number): string => {
        if (value === 1) {
            return 'I';
        } else if (value === 2) {
            return 'II';
        } else if (value === 3) {
            return 'III';
        }

        return value.toString();
    };

    const columnSize = Math.floor(24 / (rates.length + 1));

    return (
        <div style={style}>
            {showVariantHeader && (
                <Row>
                    {rates.map((_, index) => (
                        <Col
                            offset={index === 0 ? columnSize : 0}
                            span={columnSize}
                            style={variantHeaderStyle}
                        >
                            Variante {numberToRoman(index + 1)}
                        </Col>
                    ))}
                </Row>
            )}

            <Row>
                <Col span={columnSize}>Selbstbehalt</Col>
                {rates.map((rate) => (
                    <Col span={columnSize} style={centerdStyle}>
                        <div style={rateNameHeaderStyle}>{rate?.rateName}</div>
                    </Col>
                ))}
            </Row>
            <Row>
                <Col span={columnSize}>Haftpflicht</Col>
                {rates.map((rate) => (
                    <Col span={columnSize} style={centerdStyle}>
                        <div style={currencyContainerStyle}>
                            {rate && formatCurrency(rate.liabilityInsuranceNet)}
                        </div>
                    </Col>
                ))}
            </Row>
            <Row>
                <Col span={columnSize}>Vollkasko / Teilkasko</Col>
                {rates.map((rate) => (
                    <Col span={columnSize} style={centerdStyle}>
                        <div style={currencyContainerStyle}>
                            {rate && formatCurrency(rate.fullyComprehensiveNet)}
                        </div>
                    </Col>
                ))}
            </Row>

            <Divider style={dividerStyle} />

            <Row>
                <Col span={columnSize}>Prämie Netto {paymentMethod?.label}</Col>
                {rates.map((rate) => (
                    <Col span={columnSize} style={centerdStyle}>
                        <div style={currencyContainerStyle}>{rate && formatCurrency(rate.net)}</div>
                    </Col>
                ))}
            </Row>
            <Row>
                <Col span={columnSize}>Versicherungssteuer 19%</Col>
                {rates.map((rate) => (
                    <Col span={columnSize} style={centerdStyle}>
                        <div style={currencyContainerStyle}>{rate && formatCurrency(rate.tax)}</div>
                    </Col>
                ))}
            </Row>

            <Divider style={dividerStyle} />

            <Row>
                <Col span={columnSize} style={boldStyle}>
                    Prämie Brutto {paymentMethod?.label}
                </Col>
                {rates.map((rate) => (
                    <Col span={columnSize} style={boldCenteredStyle}>
                        <div style={currencyContainerStyle}>
                            {rate && formatCurrency(rate.gross)}
                        </div>
                    </Col>
                ))}
            </Row>

            {onChange && (
                <Row style={{ marginTop: 12 }}>
                    <Col span={columnSize} style={{ ...centerdStyle, justifyContent: 'start' }}>
                        SB-Variante wählen
                    </Col>
                    {rates.map(
                        (rate) =>
                            rate && (
                                <Col span={columnSize} style={centerdStyle}>
                                    <LargeRadioButton
                                        label={rate.rateName}
                                        checked={rate.rateId === selectedRateId}
                                        onSelect={(): void => onChange(rate)}
                                    />
                                </Col>
                            )
                    )}
                </Row>
            )}
        </div>
    );
};

const variantHeaderStyle: React.CSSProperties = {
    ...boldCenteredStyle,
    color: '#317383',
};

const dividerStyle: React.CSSProperties = {
    margin: '4px 0',
    borderTopColor: 'gray',
};

const currencyContainerStyle: React.CSSProperties = {
    width: '52px',
    display: 'flex',
    justifyContent: 'end',
};

const rateNameHeaderStyle: React.CSSProperties = {
    ...currencyContainerStyle,
    fontWeight: 'bold',
    width: '128px',
};
