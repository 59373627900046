import { Navigate } from 'react-router-dom';
import { UserDto } from '../../lib/shared/types';
import { Routing } from './routing';

interface Props {
    roles: string[];
    user: UserDto | null;
    children: JSX.Element;
}

export const ProtectedRoute: React.FC<Props> = ({ user, roles, children }: Props) => {
    if (!user) {
        return <Navigate to={Routing.login} replace />;
    }

    if (!user.roles.some((userRole) => roles.some((role) => userRole === role))) {
        return <div>Unauthorized</div>;
    }

    return children;
};
