export const normalizeIban = (iban: string, removeDePrefix: boolean): string => {
    const loweredIban = iban.toLowerCase();
    if (removeDePrefix && loweredIban.startsWith('de')) {
        iban = iban.substring(2, iban.length);
    }

    return iban.replace(' ', '').replace('-', '');
};

export const ibanValidator = (iban: string): Promise<void> => {
    if (typeof iban !== 'string') {
        return Promise.reject();
    }

    const country = iban.slice(0, 2).toUpperCase();
    const checkDigit = iban.slice(2, 4);
    const bban = iban.slice(4);

    // Validate IBAN check digit
    const validateAlphanumeric = `${bban}${country}${checkDigit}`;
    const validateNumberic = lettersToDigits(validateAlphanumeric);
    if (modulo97(validateNumberic) !== 1) {
        return Promise.reject();
    }

    return Promise.resolve();
};

/**
 * Checksum calculation method for IBAN
 */
export const modulo97 = (value: string): number => {
    let checksum = Number(value.slice(0, 2));
    let fragment: string;
    for (let offset = 2; offset < value.length; offset += 7) {
        fragment = `${checksum}${value.substring(offset, offset + 7)}`;
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
};

/**
 * Convert letters to digits (ASCII code minus 55)
 */
export const lettersToDigits = (string: string): string =>
    string.replace(/[a-z]/gi, (letter) => String(letter.charCodeAt(0) - 55));
