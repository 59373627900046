import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginPage, ResetPasswordPage, SetPasswordPage } from '../../authentication';
import { Roles } from '../../lib/shared/types/roles';
import { useLogin } from '../../security/login';
import { UserManagementPage } from '../../user-management';
import { SubmitWizardPage } from '../submit-wizard-page';
import { ProtectedRoute } from './protected-route';
import { Routing } from './routing';

export const RoutedContent = (): React.ReactElement | null => {
    const { user, isAutoLoginCompleted } = useLogin();

    if (!isAutoLoginCompleted) {
        return null;
    }

    return (
        <Routes>
            {!user && (
                <>
                    <Route path={Routing.login} element={<LoginPage />} />
                    <Route path={Routing.resetPassword} element={<ResetPasswordPage />} />
                </>
            )}

            <Route path={Routing.setPassword} element={<SetPasswordPage />} />

            <Route
                path={Routing.main}
                element={
                    <ProtectedRoute user={user} roles={[Roles.user, Roles.admin]}>
                        <SubmitWizardPage />
                    </ProtectedRoute>
                }
            />

            <Route
                path={Routing.userManagement}
                element={
                    <ProtectedRoute user={user} roles={[Roles.admin]}>
                        <UserManagementPage />
                    </ProtectedRoute>
                }
            />

            <Route
                path='*'
                element={<Navigate to={user ? Routing.main : Routing.login} replace />}
            />
        </Routes>
    );
};
