import { Card, Col, notification, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useAxios } from '../lib/axios';
import { GetUsersResponseDto, UserDto } from '../lib/shared/types';
import { UserEditForm, UserList } from './components';
import './user-management-page.scss';

const { Title } = Typography;

export const UserManagementPage: React.FC = () => {
    const axios = useAxios();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [users, setUsers] = React.useState<UserDto[]>([]);
    const [selected, setSelected] = React.useState<UserDto | null>(null);

    const onSelectionChanged = (value: UserDto | null): void => {
        setSelected(value);
    };

    const loadUsers = async (): Promise<void> => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get<GetUsersResponseDto>('users');
            setUsers(response?.data.users ?? []);
            if (response?.data.users.length > 0) {
                setSelected(response?.data.users[0]);
            } else {
                setSelected(null);
            }

            setLoading(false);
        } catch {
            setUsers([]);
            setSelected(null);
            setLoading(false);
        }
    };

    const onSaveUser = async (value: UserDto): Promise<void> => {
        if (value.id === 'new') {
            const response = await axios.post('create-user', value, { validateStatus: () => true });
            if (response.status === 400) {
                notification['error']({
                    message: 'Fehler beim Erstellen des Benutzers',
                    description:
                        'Beim Erstellen des Benutzers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
                });
            } else if (response.status === 500) {
                notification['error']({
                    message: 'Fehler beim Erstellen des Benutzers',
                    description:
                        'Der Benutzer wurde erstellt, es konnte jedoch keine E-Mail versandt werden, um dem Benutzer das Setzen seines Kennwortes zu ermöglichen.',
                });
            } else if (response.status === 200) {
                notification['success']({
                    message: 'Benutzer wurde erfolgreich erstellt',
                });
            }
        } else {
            const response = await axios.post('update-user', value, { validateStatus: () => true });
            if (response.status !== 200) {
                notification['error']({
                    message: 'Fehler beim Aktualisieren des Benutzers',
                    description:
                        'Beim Aktualisieren des Benutzers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.',
                });
            } else if (response.status === 200) {
                notification['success']({
                    message: 'Benutzer wurde erfolgreich aktualisiert',
                });
            }
        }

        await loadUsers();
    };

    const onDeleteUser = async (): Promise<void> => {
        const response = await axios.delete('delete-user', {
            data: selected,
            validateStatus: () => true,
        });
        if (response.status !== 200) {
            notification['error']({
                message: 'Fehler beim löschen des Benutzers',
                description:
                    'Beim löschen des Benutzers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.',
            });
        }

        setSelected(null);
        await loadUsers();
    };

    const onCreateUser = async (): Promise<void> => {
        setSelected({
            id: 'new',
            firstName: '',
            lastName: '',
            email: '',
            roles: [],
        });
    };

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Card className='user-management-page'>
                <Row gutter={[32, 8]}>
                    <Col span={24}>
                        <Title level={2}>Benutzerverwaltung</Title>
                    </Col>
                    <Col span={10}>
                        <UserList
                            loading={loading}
                            users={users}
                            selected={selected}
                            onSelected={onSelectionChanged}
                            onCreateNew={onCreateUser}
                        />
                    </Col>
                    <Col span={14}>
                        <UserEditForm
                            selectedUser={selected}
                            onSaveUser={onSaveUser}
                            onDeleteUser={onDeleteUser}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    );
};
