import React from 'react';
import { Button, Card, Typography } from 'antd';
import { EmailInput } from './components';
import { useNavigate } from 'react-router-dom';
import { useResetPassword } from '../security/reset-password';
import { Routing } from '../main-layout/routing/routing';

const { Title, Paragraph } = Typography;

export const ResetPasswordPage: React.FC = () => {
    const resetPassword = useResetPassword();
    const navigate = useNavigate();

    const [canReset, setCanReset] = React.useState<boolean>(false);
    const [resetLoading, setResetLoading] = React.useState<boolean>(false);
    const [hasBeenReset, setHasBeenReset] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>('');

    const handleEmailInput = (value: string, hasErrors: boolean): void => {
        setEmail(value);
        if (!hasErrors) {
            setCanReset(true);
        } else {
            setCanReset(false);
        }
    };

    const handleSubmit = async (): Promise<void> => {
        setResetLoading(true);
        await resetPassword(email);
        setResetLoading(false);
        setHasBeenReset(true);
    };

    const handleReturn = async (): Promise<void> => {
        navigate(Routing.login);
    };

    return (
        <div className='login-page-container'>
            <Card>
                <div className='login-card-container'>
                    {hasBeenReset && (
                        <>
                            <Paragraph>
                                Die Anforderung zum Ändern des Kennworts wurde erfolgreich an die
                                angegebene E-Mail Adresse gesendet.
                            </Paragraph>
                            <Button
                                type='primary'
                                onClick={handleReturn}
                                style={{ marginTop: '24px', marginBottom: '12px', width: '100%' }}
                            >
                                Zurück zum Login
                            </Button>
                        </>
                    )}
                    {!hasBeenReset && (
                        <>
                            <Title level={3}>Kennwort zurücksetzen</Title>
                            <Paragraph>
                                Geben Sie Ihre E-Mail Adresse ein, an die wir ein neues Kennwort
                                senden sollen.
                            </Paragraph>
                            <Typography>Email-Adresse</Typography>
                            <EmailInput value={email} onChange={handleEmailInput} />
                            <Button
                                type='primary'
                                disabled={!canReset}
                                onClick={handleSubmit}
                                loading={resetLoading}
                                style={{ marginTop: '24px', marginBottom: '12px', width: '100%' }}
                            >
                                E-Mail senden
                            </Button>
                        </>
                    )}
                </div>
            </Card>
        </div>
    );
};
