import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { DataGathering } from '../data-gathering';
import { useAxios } from '../lib/axios';
import { InsuranceTarif, Rate, RatePicker } from '../rates';
import { SubmittedView } from '../submitted';
import { DefaultOptionType } from 'antd/lib/select';
import {
    RetrieveRatesResponseDto,
    UploadDataRequestDto,
    UploadDataResponseDto,
} from '../lib/shared/types';

const paymentMethods: DefaultOptionType[] = [
    {
        label: 'monatlich',
        value: 12,
    },
    {
        label: 'jährlich',
        value: 1,
    },
];

const vehicleTypes: DefaultOptionType[] = [
    {
        label: 'bis 81 kW (110 PS)',
        value: '<=81kw',
    },
    {
        label: '82 - 110 kW (111 - 150 PS)',
        value: '82-110kw',
    },
    {
        label: '111 - 150 kW (151 - 205 PS)',
        value: '111-150kw',
    },
    {
        label: '151 - 250 kW (206 - 340 PS)',
        value: '151-250kw',
    },
];

interface FormValues {
    firstName: string;
    lastName: string;
    dateOfBirth: Date;
    street: string;
    houseNumber: string;
    postalCode: string;
    place: string;
    email: string;
    telephoneNumber: string;
    vehicleIdentificationNumber: string;
    iban: string;
    privacyNoticeAcceepted: boolean;
    brokerContractAccepted: boolean;
}

export const SubmitWizardPage: React.FC = () => {
    const axios = useAxios();

    const [insuranceTarif, setInsuranceTarif] = useState<InsuranceTarif | null>();
    const [rate, setRate] = useState<Rate | null>();
    const [paymentMethod, setPaymentMethod] = useState<DefaultOptionType | null>();
    const [kwtCat, setKwtCat] = useState<string>();
    const [processNumber, setProcessNumber] = useState<string | null>();
    const [rates, setRates] = useState<Rate[]>([]);

    const onReturnToBeginning = (): void => {
        setRate(null);
        setProcessNumber(null);
    };

    const onFormFinish = async (values: FormValues): Promise<void> => {
        const dto: UploadDataRequestDto = {
            transactionId: insuranceTarif?.transactionId ?? '',
            kwCat: insuranceTarif?.kwCat ?? '',
            rateId: rate?.rateId ?? 0,
            paymentMethod: (paymentMethod?.value as number) ?? 0,
            ...values,
            iban: 'DE' + values.iban,
        };

        try {
            const response = await axios.post<UploadDataResponseDto>('upload', dto);
            setProcessNumber(response.data?.processNumber);
        } catch (error: any) {
            console.log(error);
            if (error.response.status === 400) {
                notification['error']({
                    message: 'Fehler in den eingegebenen Daten',
                    description:
                        'Die eingegebenen Daten weisen einen Fehler auf. Bitte überprüfen Sie Ihre Eingaben.',
                });
            } else {
                notification['error']({
                    message: 'Fehler',
                    description:
                        'Es ist ein Fehler beim Versuch des Sendens aufgetreten. Bitte versuchen Sie es später erneut.',
                });
            }
        }
    };

    useEffect(() => {
        const retrieveRates = async (): Promise<void> => {
            if (paymentMethod?.value == null || kwtCat == null) {
                return;
            }

            const response = await axios.get<RetrieveRatesResponseDto>('rates', {
                params: {
                    kwCat: kwtCat,
                    paymentMethod: paymentMethod?.value?.toString(),
                },
                validateStatus: () => true,
            });

            if (response.status !== 200) {
                notification['error']({
                    message: 'Ein Fehler ist aufgetreten',
                    description:
                        'Die Daten für den ausgewählten Tarif konnten nicht geladen werden.',
                });
                setRates([]);
                return;
            }

            setInsuranceTarif(response.data);
            setRates(response.data?.rates ?? []);
        };

        retrieveRates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentMethod, kwtCat]);

    return (
        <>
            {processNumber ? (
                <SubmittedView
                    processNumber={processNumber}
                    onReturnToBeginning={onReturnToBeginning}
                />
            ) : rate ? (
                <DataGathering
                    rate={rate}
                    paymentMethod={paymentMethod}
                    onFormFinish={onFormFinish}
                />
            ) : (
                <RatePicker
                    onPaymentMethodChange={setPaymentMethod}
                    onRateChange={setRate}
                    onVehicleTypeChange={setKwtCat}
                    paymentMethods={paymentMethods}
                    rates={rates}
                    vehicleTypes={vehicleTypes}
                />
            )}
        </>
    );
};
