export const legalAgeValidator = (birthDateString: string): Promise<void> => {
    const birthDate = new Date(birthDateString);
    const now = new Date();
    let age = now.getFullYear() - birthDate.getFullYear();
    const m = now.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && now.getDate() < birthDate.getDate())) {
        age--;
    }

    if (age < 18) {
        return Promise.reject();
    }

    return Promise.resolve();
};
