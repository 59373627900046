import { Row, Typography } from 'antd';
import { CSSProperties } from 'react';
import { appVersion } from '../environment';

const { Link, Text } = Typography;

export const AppFooter: React.FC = () => {
    return (
        <Row justify='center'>
            <Link target='blank' href={`https://www.sgia.de/impressum.php`} style={linkStyle}>
                {'Impressum'}
            </Link>
            <Text style={textStyle}>{'|'}</Text>
            <Link target='blank' href={`https://www.sgia.de/datenschutz.php`} style={linkStyle}>
                {'Datenschutzerklärung'}
            </Link>
            <Text style={textStyle}>{'|'}</Text>
            <Text style={textStyle}>{'© 2022 by ZEUSS Plattform'}</Text>
            <Text style={textStyle}>{'|'}</Text>
            <Text style={textStyle}>{'Version ' + appVersion}</Text>
        </Row>
    );
};

const textStyle: CSSProperties = {
    color: 'white',
    margin: '0 6px',
};

const linkStyle: CSSProperties = {
    ...textStyle,
    textDecoration: 'underline',
};
