import React from 'react';
import './password-strength-bar.scss';

interface Props {
    score: number;
    position: number;
    colors: string[];
}

export const PasswordStrengthBarElement: React.FC<Props> = ({ score, position, colors }: Props) => {
    let color = colors[0];
    if (score >= position) {
        color = colors[score];
    }

    return <div className='password-strength-bar-element' style={{ backgroundColor: color }} />;
};
