import { Button, Dropdown, Menu, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../../security/login';
import { Routing } from '../routing/routing';
import { CSSProperties } from 'react';
import { Roles } from '../../lib/shared/types/roles';

const { Text } = Typography;

const DropdownMenu: React.FC = () => {
    const { user, logout } = useLogin();
    const navigate = useNavigate();

    const onChangePassword = async (): Promise<void> => {
        navigate(Routing.setPassword + '?type=reset');
    };

    const onLogout = async (): Promise<void> => {
        await logout();
        navigate(Routing.login);
    };

    const menuItems: MenuItemType[] = [
        {
            key: 'changePassword',
            label: 'Kennwort ändern',
            onClick: onChangePassword,
        },
    ];

    if (user && user.roles.some((value) => value === Roles.admin)) {
        menuItems.push({
            key: 'userManagement',
            label: 'Benutzerverwaltung',
            onClick: () => navigate(Routing.userManagement),
        });
    }

    menuItems.push({
        key: 'logout',
        label: 'Abmelden',
        onClick: () => onLogout(),
    });

    return <Menu items={menuItems} />;
};

export const UserMenu: React.FC = () => {
    const { user } = useLogin();

    return (
        <Dropdown overlay={<DropdownMenu />} placement='bottomLeft'>
            <Button type='text'>
                <Text style={textStyle}>{`${user?.firstName} ${user?.lastName}`}</Text>
                <DownOutlined style={iconStyle} />
            </Button>
        </Dropdown>
    );
};

const textStyle: CSSProperties = {
    color: 'white',
    fontSize: '18px',
    fontWeight: '600',
    margin: '0 6px',
};

const iconStyle: CSSProperties = {
    ...textStyle,
    margin: '0',
    position: 'relative',
    top: '4px',
};
