import React from 'react';
import { Input } from 'antd';

interface EmailInputProps {
    style?: React.CSSProperties;
    value?: string;
    onChange: (value: string, hasErrors: boolean) => void;
}

export const EmailInput = (props: EmailInputProps): JSX.Element => {
    const { onChange } = props;
    const [hasErrors, setHasErrors] = React.useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        // General Email Regex (RFC 5322 Official Standard), don't mark this as warning
        const regex =
            // eslint-disable-next-line no-control-regex
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        if (regex.test(value)) {
            setHasErrors(false);
            onChange(value, false);
        } else {
            setHasErrors(true);
            onChange(value, true);
        }
    };

    return <Input {...props} onChange={handleChange} status={hasErrors ? 'error' : ''} />;
};
