import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import React, { useRef, useState } from 'react';

const numericRegex = new RegExp('^[0-9]*$');

export const DatePickerAutoAccept: React.FC<PickerProps<Moment>> = (props: PickerProps<Moment>) => {
    return <DatePicker {...props} inputRender={InputComponent} />;
};

type InputComponentProps = React.InputHTMLAttributes<HTMLInputElement>;

const InputComponent: React.FC<InputComponentProps> = (props) => {
    const inputRef = useRef(null);
    const [isDispatchingEvent, setIsDispatchingEvent] = useState(false);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (!props.onChange) {
            return;
        }

        if (e.target.value.length === 8 && numericRegex.test(e.target.value)) {
            const day = e.target.value.substring(0, 2);
            const month = e.target.value.substring(2, 4);
            const year = e.target.value.substring(4, 8);
            const newDate = `${day}.${month}.${year}`;
            e.target.value = newDate;
        }

        props.onChange(e);

        const checkDateString = e.target.value.replaceAll('.', '');
        if (isDispatchingEvent || checkDateString.length !== 8) {
            return;
        }

        setIsDispatchingEvent(true);

        setTimeout(() => {
            try {
                console.log('begin dispatch event');

                const event = new KeyboardEvent('keydown', {
                    bubbles: true,
                    cancelable: true,
                    keyCode: 13,
                });

                const element = inputRef?.current as any;
                if (element?.dispatchEvent) {
                    console.log('dispatching now');
                    element.dispatchEvent(event);
                }

                console.log(inputRef);
            } finally {
                setIsDispatchingEvent(false);
            }
        }, 200);
    };

    return <input {...props} ref={inputRef} onChange={onChange} />;
};
