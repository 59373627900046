import { Col, Image, Row, Typography } from 'antd';
import { useLogin } from '../security/login';
import { Routing } from './routing/routing';
import { UserMenu } from './user-menu';

const { Link } = Typography;

export const AppHeader: React.FC = () => {
    const { user, isAutoLoginCompleted } = useLogin();

    if (!isAutoLoginCompleted) {
        return null;
    }

    return (
        <Row>
            <Col span={20}>
                <Link href={Routing.main}>
                    <Image src='/static/images/logo.png' preview={false} height='64px' />
                </Link>
            </Col>
            <Col span={4}>
                <div style={{ textAlign: 'right' }}>{user && <UserMenu />}</div>
            </Col>
        </Row>
    );
};
