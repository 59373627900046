import { useAxios } from '../../lib/axios';
import { ResetPasswordDto } from '../../lib/shared/types';

type ResetPasswordCallback = (email: string) => Promise<void>;

export const useResetPassword = (): ResetPasswordCallback => {
    const axios = useAxios();

    return async (email: string): Promise<void> => {
        const dto: ResetPasswordDto = {
            email: email,
        };
        await axios.post<ResetPasswordDto>('reset-password', dto);
    };
};
