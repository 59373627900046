import React from 'react';

export const boldStyle: React.CSSProperties = {
    fontWeight: 'bold',
};

export const centerdStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export const boldCenteredStyle: React.CSSProperties = {
    ...centerdStyle,
    ...boldStyle,
};
