import { BrowserRouter } from 'react-router-dom';
import './app.less';
import { AxiosProvider } from './lib/axios/use-axios';
import { MeinAutoApp } from './main-layout';
import { LoginProvider } from './security/login';
import deDE from 'antd/lib/locale-provider/de_DE';
import moment from 'moment';
import 'moment/locale/de';
import { ConfigProvider, notification } from 'antd';

export const App = (): React.ReactElement => {
    moment.locale('de');

    notification.config({ placement: 'bottomRight' });

    return (
        <AxiosProvider>
            <LoginProvider>
                <ConfigProvider locale={deDE}>
                    <BrowserRouter>
                        <MeinAutoApp />
                    </BrowserRouter>
                </ConfigProvider>
            </LoginProvider>
        </AxiosProvider>
    );
};

export default App;
