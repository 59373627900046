import { UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Button, List, Tooltip, Typography } from 'antd';
import React from 'react';
import { UserDto } from '../../lib/shared/types';
import './user-list.scss';

const { Title } = Typography;

interface UserListItemProps {
    userData: UserDto;
    selected: boolean;
    onSelected: (value: string) => void;
}

const UserListItem: React.FC<UserListItemProps> = ({
    userData,
    selected,
    onSelected,
}: UserListItemProps) => {
    const onClicked = (): void => {
        if (selected) {
            return;
        }

        onSelected(userData.id);
    };

    const className = selected ? 'user-list-item user-list-item-selected' : 'user-list-item';

    return (
        <div className={className} onClick={onClicked}>
            <UserOutlined />
            <div className='user-list-item-name'>
                {userData.lastName + ', ' + userData.firstName}
            </div>
        </div>
    );
};

interface UserListProps {
    loading: boolean;
    selected: UserDto | null;
    users: UserDto[];
    onSelected: (value: UserDto | null) => void;
    onCreateNew: () => Promise<void>;
}

export const UserList: React.FC<UserListProps> = ({
    loading,
    selected,
    users,
    onSelected,
    onCreateNew,
}: UserListProps) => {
    const onSelectionChanged = (value: string): void => {
        const user = users.find((u) => u.id === value);
        if (user) {
            onSelected(user);
        }
    };

    return (
        <div>
            <div className='user-list-header'>
                <Title level={4}>Benutzer</Title>
                <Tooltip title='Neuen Benutzer anlegen'>
                    <Button onClick={onCreateNew}>
                        <UserAddOutlined />
                    </Button>
                </Tooltip>
            </div>
            <List
                dataSource={users}
                loading={loading}
                renderItem={(item): React.ReactNode => (
                    <List.Item
                        key={item.id}
                        style={{
                            padding: '0',
                        }}
                    >
                        <UserListItem
                            userData={item}
                            selected={selected?.id === item.id}
                            onSelected={onSelectionChanged}
                        />
                    </List.Item>
                )}
            />
        </div>
    );
};
