import { Button, Card, Space, Typography } from 'antd';

const { Paragraph, Text, Title } = Typography;

interface Props {
    processNumber: string;
    onReturnToBeginning: () => void;
}

export const SubmittedView: React.FC<Props> = ({ processNumber, onReturnToBeginning }: Props) => {
    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Card style={containerStyle}>
                <Space direction='vertical'>
                    <Title level={3}>{'Vielen Dank für Ihren Antrag.'}</Title>
                    <Title level={5}>{'Ihre Vorgangsnummer ' + processNumber}</Title>
                    <Paragraph>
                        {
                            'Schön, dass Sie sich für uns entschieden haben. Ihr Antrag ist bei uns eingegangen und Sie erhalten in Kürze eine E-Mail mit allen relevanten Unterlagen zu Ihrer Versicherung.'
                        }
                    </Paragraph>
                    <Space
                        direction='vertical'
                        style={{
                            marginBottom: '24px',
                            marginTop: '24px',
                        }}
                    >
                        <Paragraph>{'Bei weiteren Fragen erreichen Sie uns per'}</Paragraph>
                        <Space direction='horizontal'>
                            <Text strong>{'E-Mail'}</Text>
                            <Text>{'info@sgia.de'}</Text>
                        </Space>
                        <Text>{'oder'}</Text>
                        <Space direction='horizontal'>
                            <Text strong>{'Telefon'}</Text>
                            <Text>{'0941595648-0 von 08:00 Uhr - 18:00 Uhr'}</Text>
                        </Space>
                        <Text italic>{'Geben Sie hier bitte kurz Ihre Vorgangsnummer an.'}</Text>
                    </Space>
                    <Text>{'Beste Grüße'}</Text>
                    <Text strong>{'Ihr ZEUSS-Team!'}</Text>
                    <Button
                        type='primary'
                        onClick={onReturnToBeginning}
                        style={{
                            marginTop: '24px',
                        }}
                    >
                        {'Zurück zur Startseite'}
                    </Button>
                </Space>
            </Card>
        </div>
    );
};

const containerStyle: React.CSSProperties = {
    marginBottom: '12px',
    maxWidth: '600px',
    minWidth: '360px',
};
