import { Button, Card, Form, Row, Select, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import React, { useState } from 'react';
import { Rate, RateVariantPicker } from '.';
import { boldCenteredStyle } from '../main-layout/styles/generic-styles';
import './rate-chooser.scss';

interface Props {
    onPaymentMethodChange: (value: DefaultOptionType) => void;
    onRateChange: (rate: Rate) => void;
    onVehicleTypeChange: (vehicleType: string) => void;
    rates: Rate[];
    paymentMethods: DefaultOptionType[];
    vehicleTypes: DefaultOptionType[];
}

export const RatePicker: React.FC<Props> = ({
    onPaymentMethodChange,
    onRateChange,
    onVehicleTypeChange,
    paymentMethods,
    vehicleTypes,
    rates,
}: Props) => {
    const [rateId, setRateId] = useState<number>();
    const [paymentMethod, setPaymentMethod] = useState<DefaultOptionType>();
    const [vehicleType, setVehicleType] = useState<string>();

    const onPaymentMethodApply = (value: DefaultOptionType): void => {
        setPaymentMethod(value);
        onPaymentMethodChange(value);
    };

    const onRateApply = (): void => {
        const rate = rates.find((rate) => rate.rateId === rateId);
        if (rate) {
            onRateChange(rate);
        }
    };

    const onVehicleTypeApply = (value: string): void => {
        setVehicleType(value);
        onVehicleTypeChange(value);
    };

    return (
        <div style={mainContainerStyle}>
            <Card style={containerStyle}>
                <Form layout='vertical'>
                    <div className='rate-chooser-form-content'>
                        <Form.Item label='Zahlweise' style={{ width: 'calc(50% - 24px)' }}>
                            <Select
                                options={paymentMethods}
                                onChange={(_, option): void =>
                                    onPaymentMethodApply(option as DefaultOptionType)
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label='Fahrzeugtyp'
                            style={{ margin: '0 24px', width: 'calc(50% - 24px)' }}
                        >
                            <Select
                                options={vehicleTypes}
                                onChange={(value, _): void => onVehicleTypeApply(value)}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </Card>

            <Card style={{ ...containerStyle, marginBottom: 0 }}>
                {vehicleType ? (
                    <div>
                        <RateVariantPicker
                            rates={rates}
                            showVariantHeader={true}
                            paymentMethod={paymentMethod}
                            onChange={(rate): void => setRateId(rate.rateId)}
                            selectedRateId={rateId}
                        />

                        <Row justify='end' style={{ marginTop: '48px' }}>
                            <Button
                                type='primary'
                                size='large'
                                disabled={!rateId || !paymentMethod}
                                onClick={onRateApply}
                            >
                                Übernehmen →
                            </Button>
                        </Row>
                    </div>
                ) : (
                    <Typography style={{ ...boldCenteredStyle, fontSize: 16 }}>
                        Fahrzeugtyp auswählen
                    </Typography>
                )}
            </Card>
        </div>
    );
};

const mainContainerStyle: React.CSSProperties = {
    flexGrow: 1,
    maxWidth: '800px',
    minWidth: '360px',
};

const containerStyle: React.CSSProperties = {
    marginBottom: '12px',
};
