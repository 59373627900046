import { createContext } from 'react';
import { UserDto } from '../../lib/shared/types';

export interface LoginContextProps {
    login: (email: string, password: string) => Promise<boolean>;
    logout: () => Promise<void>;
    user: UserDto | null;
    isAutoLoginCompleted: boolean;
}

export const LoginContext = createContext<LoginContextProps | null>(null);
