import React, { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useAxios } from '../../lib/axios';
import { LoginContext } from './login-context';
import {
    LoginRequestDto,
    LoginResponseDto,
    RefreshAccessTokenRequestDto,
    UserDto,
} from '../../lib/shared/types';

const localStorageAccessTokenKey = 'access-token';
const localStorageRefreshTokenKey = 'refresh-token';
const authorizationHeader = 'Authorization';

const createBearerToken = (token: string): string => 'Bearer ' + token;

interface LoginProviderProps {
    children: JSX.Element;
}

export const LoginProvider = ({ children }: LoginProviderProps): JSX.Element => {
    const axios = useAxios();

    let refreshPromise: Promise<void> | undefined = undefined;

    const [user, setUser] = React.useState<UserDto | null>(null);
    const [isAutoLoginCompleted, setIsAutoLoginCompleted] = useState(false);

    axios.interceptors.response.use(
        (response) => response,
        async (error: AxiosError) => {
            if (error?.response?.status === 401) {
                if (!refreshPromise) {
                    refreshPromise = tryRefreshAuthToken();
                }

                if (refreshPromise) {
                    await refreshPromise;
                }

                refreshPromise = undefined;

                const refreshedAccessToken = localStorage.getItem(localStorageAccessTokenKey);

                if (!user || !refreshedAccessToken || error.config?.headers == null) {
                    return;
                }

                error.config.headers[authorizationHeader] = createBearerToken(refreshedAccessToken);
                return axios(error.config);
            }

            return Promise.reject(error);
        }
    );

    const tryRefreshAuthToken = async (): Promise<void> => {
        const refreshToken = localStorage.getItem(localStorageRefreshTokenKey);
        const accessToken = localStorage.getItem(localStorageAccessTokenKey);

        localStorage.removeItem(localStorageRefreshTokenKey);
        localStorage.removeItem(localStorageAccessTokenKey);

        if (refreshToken == null || accessToken == null) {
            return;
        }

        const request: RefreshAccessTokenRequestDto = {
            accessToken,
            refreshToken,
        };

        try {
            const loginResponse = await axios.post<LoginResponseDto>('refreshAccessToken', request);
            await applyTokensAndLoadUser(
                loginResponse.data.accessToken,
                loginResponse.data.refreshToken
            );
        } catch (e) {
            clearLoginData();
        }
    };

    const clearLoginData = useCallback(async () => {
        localStorage.removeItem(localStorageAccessTokenKey);
        localStorage.removeItem(localStorageRefreshTokenKey);
        delete axios.defaults.headers.common[authorizationHeader];
        setUser(null);
    }, [axios.defaults.headers.common]);

    const applyTokensAndLoadUser = useCallback(
        async (accessToken: string, refreshToken: string): Promise<void> => {
            axios.defaults.headers.common[authorizationHeader] = createBearerToken(accessToken);
            localStorage.setItem(localStorageAccessTokenKey, accessToken);
            localStorage.setItem(localStorageRefreshTokenKey, refreshToken);

            const userResponse = await axios.get<UserDto>('user');
            setUser(userResponse.data);
        },
        [axios]
    );

    const login = useCallback(
        async (email: string, password: string) => {
            const dto: LoginRequestDto = {
                email,
                password,
            };
            try {
                const loginResponse = await axios.post<LoginResponseDto>('login', dto);
                await applyTokensAndLoadUser(
                    loginResponse.data.accessToken,
                    loginResponse.data.refreshToken
                );
                return true;
            } catch (e) {
                clearLoginData();
                return false;
            }
        },
        [axios, clearLoginData, applyTokensAndLoadUser]
    );

    // try perform auto login
    useEffect(() => {
        const execute = async (): Promise<void> => {
            try {
                if (user) {
                    return;
                }

                const refreshToken = localStorage.getItem(localStorageRefreshTokenKey);
                const accessToken = localStorage.getItem(localStorageAccessTokenKey);

                if (refreshToken && accessToken) {
                    await applyTokensAndLoadUser(accessToken, refreshToken);
                }
            } catch {
                await clearLoginData();
            } finally {
                setIsAutoLoginCompleted(true);
            }
        };

        execute();
    }, [applyTokensAndLoadUser, clearLoginData, user]);

    return (
        <LoginContext.Provider
            value={{
                login,
                logout: clearLoginData,
                user,
                isAutoLoginCompleted,
            }}
        >
            {children}
        </LoginContext.Provider>
    );
};
