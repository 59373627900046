import { useContext } from 'react';
import { LoginContext, LoginContextProps } from '.';

export const useLogin = (): LoginContextProps => {
    const loginContext = useContext(LoginContext);
    if (loginContext === null) {
        throw new Error(
            'LoginContext.Provider is not set in the React component tree. You should use LoginProvider as a parent component'
        );
    }

    return loginContext;
};
