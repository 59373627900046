import { Layout } from 'antd';
import { AppFooter, AppHeader, RoutedContent } from '.';
import { usePathInterceptor } from '../lib/axios';
import './mein-auto-app.scss';

const { Header, Content, Footer } = Layout;

export const MeinAutoApp: React.FC = () => {
    usePathInterceptor();

    return (
        <Layout>
            <Header>
                <AppHeader />
            </Header>

            <Content className='app-container'>
                <RoutedContent />
            </Content>

            <Footer>
                <AppFooter />
            </Footer>
        </Layout>
    );
};
